import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { zodResolver } from "@hookform/resolvers/zod";
import { cva, VariantProps } from "class-variance-authority";
import { toast } from "sonner";
import { z } from "zod";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// Export specific items from each library
export { cva, type VariantProps };
export { toast };
export { z };
export { zodResolver };
